import React from "react";

const Pricing = () => {
  return (
    <div className="pb-5 pb-md-11 pb-lg-19">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={900}
              data-aos-once="true"
            >
              <p className="text-dodger-blue-1 font-size-9 mb-9">Premium</p>
              <h2 className="font-size-11 mb-1">$59</h2>
              <span className="font-size-5 mb-2">per user, billed monthly</span>
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                <li className="mb-7">Unlimited Storage</li>
                <li className="mb-7">Unlimited Usage</li>
                <li className="mb-7">Unlimited Support</li>
                <li className="mb-7">Contact Management</li>
                <li className="mb-7">Industry standard suite of forms</li>
              </ul>
              <div className="pt-7 pt-lg-17">
                <a href="https://app.formpigeon.com/#/register">
                  <button className="btn btn-blue-3 btn-sm rounded-5">
                    Try for free &nbsp;{">"}
                  </button>
                </a>
              </div>
            </div>
          </div>
          {/* End Single Table */}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
